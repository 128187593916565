html {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
  background-color: #000000;
}

a {
  text-decoration: none;
  color: inherit;
}

.swal2-container {
  z-index: 99999999999999;
}

::-webkit-scrollbar {
  width: 5px; /* Adjust the width of the scrollbar */
}

::-webkit-scrollbar-thumb {
  background-color: lightskyblue; /* Color of the thumb (the draggable part) */
}

/* For Firefox */
body {
  scrollbar-width: thin; /* "thin" or "auto" */
  scrollbar-color: lightskyblue transparent; /* Color of the thumb and track (the background) */
}

/* For IE and Edge (optional) */
body {
  scrollbar-face-color: lightskyblue;
  scrollbar-arrow-color: transparent;
}

/* Optional: Hide scrollbar when not scrolling */
body {
  overflow: -moz-scrollbars-none;
}

body::-webkit-scrollbar {
  width: 0.5em;
}

body::-webkit-scrollbar-thumb {
  background-color: transparent;
}
